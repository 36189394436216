/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

export default {
  fetchAllFolders(_payload = {}) {
    return service.get('/documents/root', _payload)
  },
  fetchAllDocuments(id = '', _payload) {
    return service.get(`/documents/parent/${id}`, _payload)
  },
  addDocument(payload = {}) {
    return service.post('/documents', payload)
  },
  getDocumentById(id, payload = {}) {
    return service.get(`/documents/${id}`, payload)
  },
  updateDocument(id = '', payload = {}) {
    return service.patch(`/documents/${id}`, payload)
  },
  deleteDocument(id = '') {
    return service.delete(`/documents/${id}`)
  },
  bulkDeleteDocuments(ids = {}) {
    return service.post('/documents/bulk-delete', ids)
  },
  activeDocument(id) {
    return service.patch(`/documents/${id}/active`)
  },
  inactiveDocument(id) {
    return service.patch(`/documents/${id}/inactive`)
  },
  showDocument(id) {
    return service.patch(`/documents/${id}/show`)
  },
  inShowDocument(id) {
    return service.patch(`/documents/${id}/in_show`)
  },
  pinDocument(id, payload) {
    return service.patch(`/documents/${id}/pin`, payload)
  },
  getAllPins() {
    return service.get('/documents/pins')
  },
  getPromotions() {
    return service.get('/documents/promotions')
  },
}
